<template>
  <div class="vista-escritorio ">  
      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos ">           
                    

            <!-- <div class="row">
              <div
                v-for="(candidato, index) in votos.datos_candidaturas"
                :key="index"
                style="padding:10px;"
              >
                <div
                  class="resumen-voto-candidatura"
                  :class="candidato.candidato_id === votos.candidato_id_con_mayor_numero_votos
                    ? 'ganador'
                    : ''"
                  >
                  <div class="grilla-datos-candidatura">
                    <div class="foto" style="text-align:center">
                      <img
                        :src="candidato.candidato_foto_url"
                        :alt="candidato.nombre"
                        style="max-height:40px;"
                      >
                    </div>
                    <div class="nombre text-center mb-4">
                      <b>{{candidato.candidato_nombre}}</b>
                      <br/>
                      <b>{{candidato.candidato_apellidos}}</b>
                    </div>
                  </div>
                  <div class="partidos" style="text-align:center">
                    <ul>
                      <li>
                        <img :src="candidato.candidatura_logo" style="max-height:40px;">
                      </li>
                    </ul>
                  </div>
                  <div class="grilla-resumen" style="background-color:#F4F4F4">
                    <div class="total text-left">
                      <p class="mb-0 font-weight-bold">
                        Votos <br/>
                        <span>
                          {{ candidato.candidatura_total_votos_numero }}
                        </span>
                      </p>
                    </div>
                    <div class="porcentaje text-right">
                      <p class="mb-0 font-weight-bold">
                        <span>
                          {{ candidato.candidatura_total_votos_porcentaje }}%
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                
               
              </div>
            </div> -->

           <div class="scroll ">
              <div class="tabla mb-4 ">
                <table class="custom-table  ">
                  <thead>
                    <tr>
                      <th></th>
                    <th   v-for="(candidato, index) in votos.datos_candidaturas"
                        :key="index"                       
                        style="padding:10px;"
                        :class="candidato.candidato_id === votos.candidato_id_con_mayor_numero_votos
                      ? 'ganador column-header '
                      : 'noganador'" 
                      >
                    <div
                     
                      >
                        <div class="grilla-datos-candidatura ">
                          <div class="foto" style="text-align:center">
                            <img
                              :src="candidato.candidato_foto_url"
                              :alt="candidato.nombre"
                              style="max-height:30px;"
                            >
                          </div>
                          <div class="nombre text-center mb-4 ">
                            <span>{{candidato.candidato_nombre}}
                              <br/> {{candidato.candidato_apellidos}}
                            </span>
                         
                          </div>
                        </div>
                      

                        <div class="partidos mb-4" style="text-align:center ">
                              <ul>
                                <li>
                                  <img :src="candidato.candidatura_logo" style="max-height:45px;">
                                </li>
                              </ul>
                        </div>
                    </div>
                    </th>

                    </tr>
                  </thead>

                  <tbody >                  
                    <tr class="">
                      <td class="" >                    
                        <h6> 
                          <b>
                          Votos 
                          Total
                          </b> 
                        </h6>
                      </td>
                      
                      <td class="text-center " v-for="(candidato, index) in votos.datos_candidaturas"
                          :key="`votos-total-${index}`"
                          >
                          <div
                              class="resumen-voto-candidatura"
                              :class="candidato.candidato_id === votos.candidato_id_con_mayor_numero_votos
                              ? 'ganador2'
                              : 'noganador1'">

                              <span>
                                {{ candidato.candidatura_total_votos_numero }}
                              </span>        

                          </div>
       
                      </td>            
                    </tr>

                    <tr class="cell-data">
                        <td>
                          <h6>Porcentaje </h6>
                        </td> 

                        <td class="text-center" v-for="(candidato, index) in votos.datos_candidaturas">
                          <div class="resumen-voto-candidatura"
                              :class="candidato.candidato_id === votos.candidato_id_con_mayor_numero_votos
                              ? 'ganador3'
                              : 'noganador3'">

                              <span>
                                {{ candidato.candidatura_total_votos_porcentaje }}%
                              </span>

                          </div>
                        </td> 

                    </tr>

                    <!-- <tr class="cell-data">
                      <td>
                        <h6>En Casilla </h6>
                      </td>  
                      <td class="text-center" v-for="(candidato, index) in votos.datos_candidaturas">
                        <div
                              class="resumen-voto-candidatura"
                              :class="candidato.candidato_id === votos.candidato_id_con_mayor_numero_votos
                              ? 'ganador2'
                              : 'noganador1'">
                          <span>
                            {{ candidato.candidatura_total_votos_casilla }}
                          </span>        

                        </div>
                      </td>                    
                    </tr> -->

                      <!-- <tr >
                        <td>
                          <h6>Anticipado </h6>
                        </td>  

                        <td class="text-center" v-for="(candidato, index) in votos.datos_candidaturas">
                          <div  class="resumen-voto-candidatura"
                              :class="candidato.candidato_id === votos.candidato_id_con_mayor_numero_votos
                              ? 'ganador3'
                              : 'noganador3'">
                            <span >
                              {{ candidato.candidatura_total_votos_anticipados }}                          
                            </span>    
                          </div>    
                      </td>                    
                      </tr> -->

                  </tbody>
                </table>
              </div>
           </div>
         
          
          
        </div>
        </div>
      </div>
    <detalle-eleccion-candidaturas
      :resumen="resumenDistrito"
      :votos="votos"
      :esXDistrito="true"
    />
   
  </div>
</template>

<script>
import obtenerColoresDeAvatar from '@/utils/obtenerColoresDeAvatar';
import DetalleEleccionCandidaturas from '@/components/DetalleEleccionCandidaturas.vue';

export default {
  name: 'VotosPorCandidaturasEscritorio',
  components: {    
    DetalleEleccionCandidaturas,   
    
  },
  data() {
    return {
      random: 0,
      votos: {},
    };
  },
  computed: {
    distrito_nombre() {
      return this.$store.state.elementosGenerales.datos_distritos.find(d => d.id == this.distrito).descripcion;
    },
    eleccion() {
      if (this.$route.params.eleccion === 'G') {
        return 'Gubernatura';
      } if (this.$route.params.eleccion === 'D') {
        return 'Diputaciones';
      } if (this.$route.params.eleccion === 'A') {
        return 'Ayuntamientos';
      }
      return '';
    },
    ayuntamiento() {
      return this.$store.state.elementosGenerales.datos_municipios[this.distrito - 1];
    },
    distrito() {
      return this.$route.params.distrito;
    },
    distrito_nombre() {
      return this.$store.state.elementosGenerales.datos_distritos.find(d => d.id == this.distrito).descripcion;
    },
    estadisticas() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gEstadisticasNivelDistrito.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dEstadisticasNivelDistrito.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aEstadisticasNivelDistrito.municipios[this.$route.params.distrito - 1];
      }
      return {};
    },
    resumenDistrito() {
      // Enviar el índice del Distrito
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gResumenNivelDistrito.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dResumenNivelDistrito.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aResumenNivelDistrito.distritos[this.$route.params.distrito - 1];
      }
      return {};
    },
    colores() {
      return obtenerColoresDeAvatar(this);
    },
    votosXCandidatoDistritoTarjeta() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gVotosXCandidatoDistritoTarjeta.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dVotosXDistritoTarjeta.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aVotosXCandidatoDistritoTarjeta.municipios[this.$route.params.distrito - 1];
      }
      return {};
    },
    distribucion() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gDistribucionVotosCandidaturasDistrito.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dDistribucionVotosCandidaturasDistrito.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aDistribucionVotosCandidaturasDistrito.municipios[this.$route.params.distrito - 1];
      }
      return {};
    },
    sonTresSeleccionados() {
      let seleccionados = 0;
      this.votos.datos_candidaturas.map((candidatura) => {
        if (candidatura.seleccionado) {
          // eslint-disable-next-line no-plusplus
          seleccionados++;
        }
        return null;
      });

      return seleccionados === 3;
    },
  },
  methods: {
    onCheckboxChange() {
      this.$forceUpdate();
      this.random = Math.random();
    },
    obtenerAlt(ruta) {
      return ruta.replace('/emblemas/', '')
        .replace('.jpeg', '');
    },
    setearVotos() {
      const niveles = {
        G: 'gVotosXCandidatoDistritoTarjeta',
        A: 'aVotosXCandidatoDistritoTarjeta',
        D: 'dVotosXCandidatoDistritoTarjeta',
      };

      const { distrito, eleccion } = this.$route.params;

      const estado = this.$store.state[
        niveles[eleccion]
      ];

      if (eleccion === 'A') {
        this.votos = estado?.municipios[distrito - 1];
      } else {
        this.votos = estado?.distritos[distrito - 1];
      }
    },
  },
  created() {
    this.setearVotos();
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.setearVotos();
      },
    },
  },
};
</script>

<style lang="scss" scoped>


.titulos {
  width: 100%;

  h3 {
     font-size: 26px;
     color: black;
     font-weight: bold;
     border-left: 15px solid #582D73;
     padding-left: 10px;
  }
}
.scroll{
  // white-space: nowrap; 
  // margin-top: 10px;
  overflow-x: auto; 
  
}
.tabla{
  width: 100px; 
  
  .custom-table {
    border-collapse: separate; 
    width: max-content; 
    white-space: nowrap;  
    border-spacing: 15px 0px ; 
    
  }

  // td:not(:first-child) {
  // border-left: 1px solid black; 
  // border-right: 1px solid black; 
 

  // }
  // th:not(:first-child) {
  // border-left: 1px solid black; 
  // border-right: 1px solid black; 
  // padding: 5px;  
  // }  
  // thead > tr:first-child > th:not(:first-child) {
  // border-top: 1px solid black; /* Borde superior */  
  // }
  // tbody > tr:last-child > th:not(:first-child) {
  // border-bottom: 1px solid black; /* Borde inferior */
  // }
  // tbody > tr:not(:last-child) {
  // border-bottom: 1px solid rgb(178, 173, 173)!important; /* Borde inferior */
  
  // }
    
}
.resumen-voto-candidatura {
  display: grid;
  padding: 3px;  
  min-width: 120px;
  max-width: 200px;
  .grilla-datos-candidatura {
    margin: 10px;
    .foto > svg {
      max-width: 50px;
      margin: 0 auto;
      display: block;
      margin-bottom: 10px;
    }
  }
 
}


.ganador {
  
  border: 3px solid #B283B9; 
  border-bottom: none;
  width: 170px;
  max-width: 200px;
  // .total {
  //   p > span {
  //     color: #D2127E;
  //   }
  // }
  span{
    font-size: 65%;
    max-width: 140px;
  }

 
}
.ganador2 {
   border: 3px solid #B283B9; 
  border-bottom: none;
  border-top: none;
  //width: 140px;
    max-width: 200px;
  
    span{
    font-size:100%;
    max-width: 140px;
  }

 
}
.ganador3 {
  border: 3px solid #B283B9;  
  border-top: none;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;

  span{
    font-size:100%;
    max-width: 140px;
  }


 
}
.ganador4 {
  border: 3px solid #D2127E; 
  border-bottom: none;

  span{
    font-size:100%;
    max-width: 140px;
  }


 
}


.noganador{
  border: 1px solid #000000; 
  border-bottom: none;
  width: 170px;
  max-width: 200px;
  span{
    font-size: 65%;
    max-width: 140px;
  }
}
.noganador1{
  border: 1px solid #000000; 
  border-bottom: none;
  border-top:none ;
  // width: 140px;
  // max-width: 150px;
}
.noganador3{
  border: 1px solid #000000; 
  // border-bottom: none;
  border-top:none ;
  border-radius: 0 0 15px 15px;
  
}

.boton-seleccionar {
  width: 100%;
  display: block;
  text-align: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #582D73;
  border-color: transparent;

  label {
    border: none;
    width: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.seleccionado {
  background-color: lighten(#582D73, 10%);
}

.resumen-votacion {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 15px;

  .grilla-suma {
    display: grid;
    grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
    /* max-width: 900px; */
    align-items: center;
    gap: 30px;

    .acumulados, .nulos, .no-registradas, .total {
      p {
        color: #582D73;
      }
    }

    .total {
      big {
        font-size: 2rem;
      }
      big, .porcentaje  {
        color: #D2127E;
      }
    }
  }
}

.tabla-distribucion-votos {
  tbody {
    tr {
      vertical-align: top;
      td {
        svg {
          max-width: 50px;
        }

        &:nth-child(2n) {
          width: 50%;
        }
      }
      .voto {
        text-align: center;
        background: transparent;
        p {
          font-size: 1.5rem !important;
          font-weight: bold !important;
        }
        .titulo{
          font-size: 1rem !important;
          font-weight: bold !important;
        }
        svg{
            background-color: black;
            color: white;
            padding: 5px;
            border-radius: 50px;
            width: 20px;
            font-size: 20px;
        }
      }
      .votos {
        text-align: center;
        background: transparent;
        p {
          font-size: 1.5rem !important;
          font-weight: bold !important;
        }
        .titulo{
          font-size: 1rem !important;
          font-weight: bold !important;
        }
        svg{
            background-color: black;
            color: white;
            padding: 5px;
            border-radius: 50px;
            width: 20px;
            font-size: 20px;
        }
      }
    }
  }
}

.boton-verdetalle {
  background-color: #582D73;
  border-color: transparent;
}

</style>
