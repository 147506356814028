<template>
  <div class="col-12 col-md-6 mb-4">
    <div class="distribucion-votos">
      <div class="grilla-datos-candidatura mb-3">
        <div class="foto">
          <img
            v-if="candidato.candidato_nombre !== 'TOTAL'"
            :src="candidato.candidato_foto_url"
            style="width:60px;height:60px;" alt=""
          >
        </div>
        <div class="nombre" v-if="candidato.candidato_nombre !== 'TOTAL'">
          <b>{{candidato.candidato_nombre}} {{candidato.candidato_apellidos}}</b>
        </div>
        <div class="nombre" v-else>
          <b>{{candidato.candidato_nombre}}</b>
        </div>
      </div>
      <div
        v-for="(partido, index) in  candidato.datos_votos"
        class="grilla-datos-candidatura mb-2"
        :key="index"
      >
        <div
          class="partido"
        >
          <img
            v-if="candidato.candidato_nombre !== 'TOTAL'"
            :src="partido.logo_integrante"
            style="width:20px;height:20px;"
            alt=""
          >
        </div>
        <div class="nombre">
          <p class="mb-0 text-right font-weight-bold">
            {{ partido.votos_integrante }}
          </p>
        </div>
      </div>
      <div class="grilla-totales mb-3 border-0">
        <div>
          <p class="mb-0 primario">
            Total de votos por Candidatura
          </p>
        </div>
        <div class="nombre">
          <p class="mb-0 primario font-weight-bold text-right">
            {{ candidato.candidatura_total_votos_numero }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import obtenerColoresDeAvatar from '@/utils/obtenerColoresDeAvatar';

export default {
  name: 'DistribucionVotos',
  props: {
    candidato: Object,
  },
  computed: {
    colores() {
      return obtenerColoresDeAvatar(this);
    },
  },
};
</script>

<style lang="scss" scoped>
.grilla-datos-candidatura {
  display: grid;
  grid-template-columns: 50px 1fr;
  gap: 30px;
  border-bottom: 1px solid #dcdcdc;
}
.grilla-totales {
  display: grid;
  grid-template-columns: 1fr 33%;
  gap: 30px;
}
</style>
