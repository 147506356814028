<template>
  <Layout>
    <div class="row votosPorCandidaturaMovil" v-if="cargaTerminada">
      <div class="col-12 col-md-10 mx-auto">
        <router-link
          :to="`/${eleccion}/Secciones/${distrito}/${listaSecciones[0].seccion_id}`"
          class="
            btn btn-secondary btn-link text-center
            font-weight-bold mb-3 mx-auto d-block text-white w-75
          ">
          Sección
          </router-link>

        <h4>
          {{ titulo }} -
          <b class="primario" >Detalle del Distrito</b> -
          <span class="primario">Votos por Candidatura </span>
        </h4>

        <p>
          <router-link :to="`/${eleccion}/ENT/VPC`" class="estado-volver">
            Baja California Sur
          </router-link> / <b>
            <span v-if="eleccion != 'A'">
              Distrito {{distrito}}. {{distrito_nombre}}
            </span>
            <span v-else>
              Municipio {{ayuntamiento.municipio_id}}. {{ayuntamiento.municipio_descripcion}}
            </span>
          </b>
        </p>

        <p>
          El total de votos mostrado a nivel Entidad representa la suma
          del voto emitido en territorio Estatal y en el Extranjero.
        </p>

        <p class="mb-5">
          Por representación, los decimales de los porcentajes muestran sólo cuatro digitos.
          No obstante, al considera todos los decimales, suman 100%.
        </p>

        <!-- <div class="totalVotos mb-5">
          <h5 class="text-center"><b>Total de votos</b></h5>

          <h1 class="total mb-0">
            {{ resumenDistrito.total_votos_general_numero }}
          </h1>
          <p class="porcentaje mb-3">
            {{ resumenDistrito.total_votos_general_porcentaje }}%
          </p>
        </div> -->

        <div class="votosEnActasContabilizadas">
          <p class="titulo">
            Votos en Actas Contabilizadas
          </p>

         
        </div>

        <div class="row ">
          <div
            class="col-12 col-md-6 mb-4"
            v-for="(candidato, index) in votosXCandidatoDistritoTarjeta.datos_candidaturas"
            :key="index"
          >
            <div
              class="resumen-voto-candidatura"
              :class="obtenerClaseGanador(candidato)"
            >
              <div class="grilla-datos-candidatura">
                <div class="foto ">
                  <!-- <img src="@/assets/avatar-persona.svg" :alt="candidato.nombre"> -->
                  <img
                    :src="candidato.candidato_foto_url"
                    :alt="candidato.nombre"
                    style="max-height:60px;"
                  >

                  <!-- <avatar :colores="colores" /> -->
                </div>
                <div class="nombre">
                  <div class="mb-2">
                    <b>{{candidato.candidato_nombre}} {{candidato.candidato_apellidos}}</b>
                  </div>
                  <img
                    :src="candidato.candidatura_logo"
                    :alt="candidato.nombre"
                    style="max-height:30px;"
                  >

                </div>
                <div class="seleccion">
                </div>
              </div>
              <div class="grilla-votos mb-2">
                <div class="total text-left">
                  <p class="mb-0 font-weight-bold">
                    Total de votos <br />
                    <span>
                      {{ candidato.candidatura_total_votos_numero }}
                    </span>
                  </p>
                </div>
                <div class="porcentaje text-right">
                  <p class="mb-0 font-weight-bold">
                    <span>
                      {{ candidato.candidatura_total_votos_porcentaje }}%
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p class="text-justify">
            Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos.
            No obstante, al considerar todos los decimales,suman 100%.
          </p>
        </div>

        <div class="resumenDeLaVotacion mb-5">

          <p class="titulo">
            Resumen de la votación
          </p>

          <!-- Votos acumulados -->
          <div class="d-flex ">
            <div class="icono pt-4 mr-2">
              <font-awesome-icon icon="plus" class="fa-2x" />
            </div>
            <ResumenVotacion
            texto="Votos acumulados"
            :numero="resumenDistrito.total_votos_acumulados_numero"
            :porcentaje="resumenDistrito.total_votos_acumulados_porcentaje" />
            <span id="tooltip" class="mt-3">
              <font-awesome-icon
              icon="info-circle"
              class="fa fa-info fa-lg"
              @click="mostrarModalVotoAcumulado"
              />
            </span>

            <ModalBaseMovil :textoModal="votoAcumulado"  ref="modalRefVotoAcumulado" />

          </div>
          <!-- Votos por Candidaturas no registradas -->
          <div class="d-flex ">
            <div class="icono-suma pt-4 mt-3 mr-2 ml-2">
              <font-awesome-icon icon="plus" class="fa-2x" />
            </div>
            <ResumenVotacion
              texto="Candidaturas no registradas"
              :numero="resumenDistrito.total_votos_candidatura_noregistrada_numero"
              :porcentaje="resumenDistrito.total_votos_candidatura_noregistrada_porcentaje" />

          </div>
          <!-- Votos Nulos -->
          <div class="d-flex ">
            <div class="icono-suma pt-4 mt-3 mr-2 ml-2">
              <font-awesome-icon icon="plus" class="fa-2x" />
            </div>

            <ResumenVotacion
              texto="Nulos"
              :numero="resumenDistrito.total_votos_nulos_numero"
              :porcentaje="resumenDistrito.total_votos_nulos_porcentaje" />

          </div>

          <div class="total-votos mt-5">
            <div class="d-flex justify-content-center">
              <h5 class="text-center mr-2 mt-2">Total de votos</h5>

                  <span id="tooltip" class="info-votos">
                    <font-awesome-icon
                      icon="info-circle"
                      class="fa fa-info fa-lg "
                      @click="mostrarModalTotalVotos"
                      />

                  </span>
                  <ModalBaseMovil :textoModal="totalVotos" v-if="esVotototal" ref="modalRefVotosTotales" />

            </div>
            <div class="d-flex">
              <div class="icono-suma ml-4 mt-2 pt-1">
                      <font-awesome-icon icon="equals" class="fa-2x" />
              </div>

              <div class=" ml-5  text-center ">
                <h1> <strong>{{ sumaTotal }}</strong> </h1>
              </div>

            </div>
            <div class="text-center ">
              <h4>
                {{ resumenDistrito.total_votos_general_porcentaje }}%
              </h4>
            </div>
          </div>

        </div>






        <div class="votosEnActasContabilizadas mb-3">
          <p class="titulo">
            Distribución de votos por candidatura a nivel Distrito 
          </p>

          <p>
            La tabla muestra el desglose de votos que cada candidatura presenta en el Distrito,
            indicando los votos recibidos de manera individual y, en su caso,
            los votos recibidos vía coalición.
          </p>

          <a

            target="__blank"
            rel="noopener noreferrer"
            v-b-modal.ConocePorcentajeParticipacionCiudadana
          >
            <u>
              Conoce cómo se suman los votos para Candidaturas
              de Coalición de acuerdo a la legislatura vigente.
            </u>
          </a>
        </div>

        <div class="row mb-3">
          <distribucion-votos
            v-for="(candidato, index) in distribucion.datos_candidatos"
            :key="index"
            :candidato="candidato"
          />
        </div>

        <div class="voto-anticipadoF2 d-flex justify-content-center mb-5">          
          <!-- <img class="fondo" src="/src/assets/generales/Enmascarar grupo 5.png" alt="">               -->
          <div class="votos-cabecera ">
            <div >
         
              <div class="mb-4 ml-5 pl-3 mt-4 ">
                <img src="/img/votoA.png" height="50px">
              </div>

              <div class=" ml-3 mb-3">
                <h4>Voto Anticipado</h4>
              </div>

            
              <div v-if="eleccion =='Diputaciones' ">                  
                <div v-if="listaDistritos !='8' && listaDistritos !='9' && listaDistritos !='10' && listaDistritos !='11' && listaDistritos !='13'" >
                    <router-link :to="{ name: 'VotoAnticipado' }" >
                      <button  class="btn-secondary btn-link text-center
                        font-weight-bold mb-3 mx-auto d-block text-white w-75 mb-3 ml-5">Ver Resultados </button>
                    </router-link>
                    <br>
                </div>
                  <div v-else class=" ml-3 mb-3" >
                    <h6>Esta Alternativa de Votación
                      <br>
                      no opera en este Distrito.</h6>
                  </div>
              </div>

              <div v-else>
                  <div v-if="ayuntamiento.municipio_id !='1' && ayuntamiento.municipio_id !='5' "  class="ml-3">
                      <router-link :to="{ name: 'VotoAnticipado' }" >
                        <button  class="btn btn-secondary btn-link text-center
                          font-weight-bold  mx-auto d-block text-white  mb-3 ml-5">Ver Resultados </button>
                      </router-link>
                      <br>
                  </div>                
                  <div v-else class="  mb-3" >
                    <h6>Esta Alternativa de Votación
                      <br>
                      no opera en este Municipio.</h6>
                  </div>      
              </div>
              

           </div>
         </div>
        </div>

        <div class="votosEnActasContabilizadas">
          <p class="titulo">
            Estadística
            <template v-if="eleccion != 'A'">
              Distrito {{distrito}}. {{ distrito_nombre }}
            </template>
            <template v-else>
              Municipio {{ayuntamiento.municipio_id}}. {{ayuntamiento.municipio_descripcion}}
            </template>
          </p>
        </div>

        
        <estadistica-entidad />
        <lista-nominal />
        
      </div>

      <detalle-eleccion
        :resumen="resumenDistrito"
        :votos="votosXCandidatoDistritoTarjeta"
        :esXDistrito="true"
        :key="random"
      />

      <ConocePorcentajeParticipacionCiudadana />
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/Movil/layouts/Layout';
import ConocePorcentajeParticipacionCiudadana from '@/components/ConocePorcentajeParticipacionCiudadana.vue';
import ResumenVotacion from '@/components/Movil/componentes/ResumenVotacion';
import DistribucionVotos from '@/components/Movil/componentes/DistribucionVotos.vue';
import ProgresoVertical from '@/components/Movil/componentes/ProgresoVertical.vue';
import DetalleEleccion from '@/components/DetalleEleccionCandidaturas.vue';
import ListaNominal from '@/components/ListaNominal.vue';
import EstadisticaEntidad from '@/components/EstadisticaEntidad.vue';
import ModalBaseMovil from '@/components/Movil/componentes/ModalBase.vue';

import obtenerColoresDeAvatar from '@/utils/obtenerColoresDeAvatar';

export default {
  name: 'VpcDetalleDelDistritoMovil',
  components: {
    Layout,
    ResumenVotacion,
    DistribucionVotos,
    ModalBaseMovil,
    ProgresoVertical,
    DetalleEleccion,
    ListaNominal,
    EstadisticaEntidad,
    ConocePorcentajeParticipacionCiudadana,
  },
  data() {
    return {
      random: 0,
      esVotototal: true,
      show: false,
      esVotototal: true,
      votoAcumulado:
      "Es la suma de los votos en territorio estatal (Casillas y, en su caso Anticipados) desenglosandos por Partido Político, Coalición y Candidatura Independiente.",
      totalVotos:
      "Se refiere a la suma total de votos que realiza el sistema informático con base en los datos que muestran en cada Acta; podrás consultar la cifra del total de votos mostrados en cada Acta en la base de datos de PREP (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).",
      votoNulo:
      "El total y el porcentaje de votos para candidaturas no registradas y nulos, considera la votación en Casillas y Anticipados." ,

    };
  },
  computed: {
    sumaTotal() {
      const votosAcumulado = this.resumenDistrito.total_votos_acumulados_numero;
      const votosNR = this.resumenDistrito.total_votos_candidatura_noregistrada_numero ;
      const votosN = this.resumenDistrito.total_votos_nulos_numero;

      let sumaTotal = 0;
      //Quita la coma
      if (votosAcumulado !== '0') {
        sumaTotal += parseInt(votosAcumulado.replace(',', ''));
      }
      if (votosNR !== '0') {
        sumaTotal += parseInt(votosNR.replace(',', ''));
      }
      if (votosN !== '0' ) {
        sumaTotal += parseInt(votosN.replace(',', ''));
      }
      //Regresa la suma y le agrega la coma
      return sumaTotal.toLocaleString('en-US');
    },

    distrito_nombre() {
      return this.$store.state.elementosGenerales.datos_distritos.find(
        (d) => d.id === this.distrito,
      ).descripcion;
    },
    titulo() {
      if (this.$route.params.eleccion === 'G') { return 'Gubernatura'; }
      if (this.$route.params.eleccion === 'A') { return 'Ayuntamiento'; } return 'Diputaciones';
    },
    eleccion() {
      return this.$route.params.eleccion;
    },
    distrito() {
      return this.$route.params.distrito;
    },
    ayuntamiento() {
      return this.$store.state.elementosGenerales.datos_municipios[this.distrito - 1];
    },
    colores() {
      return obtenerColoresDeAvatar(this);
    },
    colorEsperadas() {
      if (this.$store.state.esOscuro) {
        return 'fd2a71';
      }
      return 'd2127e';
    },
    colorCapturadas() {
      if (this.$store.state.esOscuro) {
        return 'fe9db0';
      }
      return '79144c';
    },
    colorContabilizadas() {
      if (this.$store.state.esOscuro) {
        return 'fec5cd';
      }
      return 'efb5d3';
    },
    cargaTerminada() {
      return this.$store.state.cargaTerminada;
    },
    votosXCandidatoDistritoTarjeta() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gVotosXCandidatoDistritoTarjeta.distritos[
          this.$route.params.distrito - 1
        ];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dVotosXDistritoTarjeta.distritos[this.$route.params.distrito - 1];
      }
      return this.$store.state.aVotosXCandidatoDistritoTarjeta.municipios[
        this.$route.params.distrito - 1
      ];
    },
    resumenDistrito() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gResumenNivelDistrito.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dResumenNivelDistrito.distritos[this.$route.params.distrito - 1];
      }
      return this.$store.state.aResumenNivelDistrito.distritos[this.$route.params.distrito - 1];
    },
    distribucion() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gDistribucionVotosCandidaturasDistrito.distritos[
          this.$route.params.distrito - 1
        ];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dDistribucionVotosCandidaturasDistrito.distritos[
          this.$route.params.distrito - 1
        ];
      }
      return this.$store.state.aDistribucionVotosCandidaturasDistrito.municipios[
        this.$route.params.distrito - 1
      ];
    },
    estadisticas() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gEstadisticasNivelDistrito.distritos[
          this.$route.params.distrito - 1
        ];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dEstadisticasNivelDistrito.distritos[
          this.$route.params.distrito - 1
        ];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aEstadisticasNivelDistrito.municipios[
          this.$route.params.distrito - 1
        ];
      }

      return {};
    },
    listaSecciones() {
      if (this.eleccion !== 'A') {
        return this.$store.state.elementosGenerales.datos_secciones
          .distritos[this.distrito - 1]
          .datos_secciones;
      }
      return this.$store.state.elementosGenerales
        .datos_secciones_por_municipio[this.distrito - 1]
        .datos_secciones;
    },
  },
  methods: {
    mostrarModalVotoNulo() {
      this.$refs.modalRefVotoNulo.show();

    },
    mostrarModalVotoAcumulado() {
      this.$refs.modalRefVotoAcumulado.show();

    },
    mostrarModalTotalVotos() {
      this.$refs.modalRefVotosTotales.show();
    },
    actualizarDetalleEleccion() {
      this.$forceUpdate();
      this.random = Math.random();
    },
    obtenerClaseGanador(candidato) {
      const votos = this.votosXCandidatoDistritoTarjeta;
      return candidato.candidato_id === votos.candidato_id_con_mayor_numero_votos
        ? 'ganador'
        : '';
    },
    calcularAvance(avance) {
      return Math.ceil(parseFloat(avance));
    },
  },
};
</script>

<style lang="scss" scoped>
.boton-rosa{
  background-color:#C791A8 ;
  color: #ffff;
  border-color: none;
  border-shadow: none;
  border-radius: 10px;
  font-weight: bold;
  width: 150px;
}
.icono {
  background-color: #d3097f00;
  svg {
    color: #d3097f00;
  }
}
.ganador {
  border: 3px solid #b283b9;
  .grilla-votos {
    p > span {
      color: #000000;
    }
  }
}

.voto-anticipado{
  background-image: url('/src/assets/generales/Enmascarar grupo 5.png');
 background-color: #000000;
    
 
  button{
    background-color:#C791A8 ;
    border-radius: 10px;
    border: none;
    height: 40px;
    width: 160px;
    font-weight: bold;
  }
}
.resumen-voto-candidatura {
  background-color: #f5f5f5;
  padding: 10px;

  .grilla-datos-candidatura {
    display: grid;
    grid-template-columns: 75px 1fr 30px;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;

    .foto {
      img {
        max-height: 50px;
        display: block;
        margin: 0 auto;
      }
    }

    .nombre {
      font-size: 16px;
    }
  }

  .grilla-votos, .grilla-distribucion-votos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 30px;
  }

  .grilla-distribucion-votos {
    background-color: #d3d3ec;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: -10px;
    padding: 10px;
  }
}

.btn-link {
  border-radius: 15px;
  background-color: #C791A8;
  border-color: transparent;
}
</style>
